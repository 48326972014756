import { UserService } from "@air-gmbh/data-access/auth";
import { OptInService } from "@air-gmbh/data-access/data-protection";
import { HouseholdService } from "@air-gmbh/data-access/household";
import { REDIRECT_LOGIN, ROLES, TourStatus } from "@air-gmbh/util/constants";
import { AirError, MissingIdError } from "@air-gmbh/util/error";
import { Injectable } from "@angular/core";
import { combineLatest, merge, Observable, of, partition } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { C } from "ts-toolbelt";
import { TourNavigationService } from "./tour-navigation.service";

export class TourNotFinishedError extends AirError {
  constructor(source: C.Class | string) {
    super(source, "Client did not finish tour");
  }
}

/**
 * A service which generates the redirect route for a user's homepage.
 */
@Injectable({
  providedIn: "root",
})
export class RedirectService {
  constructor(
    private readonly userService: UserService,
    private readonly householdService: HouseholdService,
    private readonly tourService: TourNavigationService,
    private readonly optInService: OptInService
  ) {}

  getRedirectRouteForRole(role: ROLES): Observable<Array<string>> {
    const [client$, other$] = partition(
      of(role),
      (aRole) => aRole === ROLES.CLIENT
    );

    const clientRoute$ = client$.pipe(
      switchMap(() => this.userService.getUserId()),
      switchMap((userId) =>
        combineLatest([
          of(userId),
          this.tourService.fetchTourStatus(userId),
          this.optInService.isOptInApproved(userId),
        ])
      ),
      switchMap(([userId, tourStatus, isOptInApproved]) => {
        if (isOptInApproved) {
          if (tourStatus !== TourStatus.Airboard) {
            return of(["tour-not-finished", userId]);
          }
          return this.householdService.fetchIds(userId).pipe(
            map((householdIds) => {
              const currentClientHouseholdId = householdIds.client.current;
              if (currentClientHouseholdId == null) {
                throw new MissingIdError(
                  RedirectService,
                  "currentClientHouseholdId"
                );
              }
              return currentClientHouseholdId;
            }),
            map((currentClientHouseholdId) => [
              "airboard",
              currentClientHouseholdId,
            ])
          );
        } else {
          return of(["opt-in"]);
        }
      })
    );

    const otherRoute$ = other$.pipe(
      map((aRole): string[] => {
        const roleRedirect = REDIRECT_LOGIN[aRole];
        return roleRedirect ? [roleRedirect] : [""];
      })
    );

    return merge(clientRoute$, otherRoute$);
  }

  /**
   * Creates the home page route of the currently logged in user.
   *
   * @returns The home page route
   */
  getRedirectRoute(): Observable<Array<string>> {
    return this.userService
      .getHighestRole()
      .pipe(switchMap((role) => this.getRedirectRouteForRole(role)));
  }
}
