<footer class="flex flex-col items-center justify-center gap-4 p-2 sm:flex-row">
  <a [href]="privacyPolicy" target="_blank">
    {{ "links.privacyPolicy" | translate }}
  </a>
  <a [href]="privacyInformation" target="_blank">
    {{ "links.privacyInformation" | translate }}
  </a>
  <a [href]="imprint" target="_blank">
    {{ "links.imprint" | translate }}
  </a>
</footer>
