<button
  class="relative flex w-full items-center justify-center gap-3 rounded-xl border-2 px-5 py-2 text-base font-normal shadow-sm duration-300 sm:w-auto {{
    buttonClass
  }}"
  [type]="buttonType"
  [ngClass]="[
    variant,
    buttonClasses,
    isLoading ? 'button--loading cursor-default opacity-75' : ''
  ]"
  [disabled]="disabled"
  (click)="onClick($event)"
>
  <air-icon
    *ngIf="leftIcon || onlyIcon"
    [size]="onlyIcon ? 'xl' : 'lg'"
    [ngClass]="{
      'animate-spin-half': isSpinning,
      'opacity-0': isLoading
    }"
    [faClasses]="leftIcon ?? onlyIcon"
    (animationend)="isSpinning = false"
  ></air-icon>
  <ng-container *ngIf="!isLoading && !onlyIcon">
    <ng-container *ngTemplateOutlet="buttonContent"> </ng-container>
  </ng-container>
  <div
    *ngIf="isLoading"
    [ngClass]="{
      'opacity-0': isLoading
    }"
    (click)="$event.stopPropagation()"
  >
    <ng-container *ngTemplateOutlet="buttonContent"> </ng-container>
  </div>
  <air-icon
    *ngIf="rightIcon"
    size="lg"
    [ngClass]="{
      'animate-spin-half': isSpinning,
      'opacity-0': isLoading
    }"
    [faClasses]="rightIcon"
    (animationend)="isSpinning = false"
  ></air-icon>

  <div
    *ngIf="isLoading"
    class="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center"
    (click)="$event.stopPropagation()"
  >
    <air-spinner [size]="spinnerSize" [color]="spinnerColor"></air-spinner>
  </div>
</button>

<ng-template #buttonContent>
  <ng-content></ng-content>
</ng-template>
